import React, { FC, useState } from 'react';
import { RecommendationsProps } from './models';
import RecommendationsListItem from './RecommendationsListItem';

import './Recommendations.scss';

const Recommendations: FC<RecommendationsProps> = ({
  recommendations: { recommendationsTitle, recommendationsList, learnMoreLabel },
  productNodes,
}) => {
  const [isActive, setActive] = useState(-1);

  const recommendationToggle = (index) => (isActive === index ? setActive(-1) : setActive(index));

  return (
    <div className="recommendations">
      <h2 className="recommendations__title">{recommendationsTitle}</h2>
      <div className="recommendations__items" data-testid="recommendations-cards">
        {recommendationsList?.map((recommendationsListItem, index) => (
          <RecommendationsListItem
            key={recommendationsListItem.recommendationsItemTitle}
            recommendationsListItem={recommendationsListItem}
            learnMoreLabel={learnMoreLabel}
            productNodes={productNodes}
            active={isActive === index}
            isActive={isActive}
            recommendationToggle={() => recommendationToggle(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
