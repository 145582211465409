/* eslint-disable */
// @ts-nocheck
const tryInteractSnippet = (tryInteractId: string) => {
  let interactApp: {
    initialize: (arg0: {
      appId: string;
      width: string;
      height: string;
      async: boolean;
      host: string;
      redirect_host: boolean;
      no_cover: boolean;
    }) => void;
    display: () => void;
  };
  (function (d, t) {
    const s = d.createElement(t) as HTMLScriptElement;
    const options = {
      appId: tryInteractId,
      width: '800',
      height: '800',
      async: true,
      host: 'quiz.tryinteract.com',
      redirect_host: true,
      no_cover: true,
    };
    s.src = 'https://i.tryinteract.com/embed/app.js';
    s.id = 'tryInteract';
    s.onload = s.onreadystatechange = function () {
      const rs = this.readyState;
      if (rs) if (rs != 'complete') if (rs != 'loaded') return;
      try {
        interactApp = new InteractApp();
        interactApp.initialize(options);
        interactApp.display();
      } catch (e) {}
    };
    const scr = d.getElementsByTagName(t)[0];
    const par = scr.parentNode;
    par.insertBefore(s, scr);
  })(document, 'script');
};

export default tryInteractSnippet;
