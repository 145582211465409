import React, { FC, useContext } from 'react';
import Slider from 'react-slick';
import UmbracoImage from 'common/Image/UmbracoImage';
import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { navigateToPage } from 'utils/functions';
import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import { CAROUSEL_DEFAULT_SETTINGS } from 'utils/constants';
import { CarouselProps } from './models';

import './carousel.scss';

const Carousel: FC<CarouselProps> = ({
  carouselImages,
  className,
  settings = CAROUSEL_DEFAULT_SETTINGS,
  children,
}) => {
  const screenRecognitionContext = useContext(ScreenRecognitionContext);
  const carouselImageItems = screenRecognitionContext.isXS
    ? carouselImages?.carouselItemsMobile
    : carouselImages?.carouselItemsDesktop;

  return (
    <Slider
      {...settings}
      className={className}
      beforeChange={(_current, next) => {
        const isLastSlide = next % 1 !== 0;
        const slickDotsContainer = document.querySelector('.slick-dots');
        const slickDots = slickDotsContainer!.querySelectorAll('li');
        const slickDotsLength = slickDots!.length - 1;
        const lastSlickDot = slickDots[slickDotsLength];

        if (isLastSlide) {
          lastSlickDot.classList.add('slick-active');
        } else {
          lastSlickDot.classList.remove('slick-active');
        }
      }}
    >
      {children ||
        carouselImageItems!.map(({ image, imageAlt, text, button }) => (
          <div className="banner-carousel-slider__item-wrapper">
            <UmbracoImage key={image.fallbackUrl} image={image} alt={imageAlt} />
            <div className="banner-carousel-slider__content">
              <DangerouslySetInnerHtml className="banner-carousel-slider__text" html={text} />
              {button?.map((item, i) => (
                <Button
                  ariaLabel={item.ariaLabel}
                  onClick={() => navigateToPage(item.link[0].url)}
                  className="banner-carousel-slider__btn"
                  key={`${item.ariaLabel}-${i}`}
                >
                  {item.text}
                </Button>
              ))}
            </div>
          </div>
        ))}
    </Slider>
  );
};

export default Carousel;
